body {
  --darkness: 0.6;
  background-color: #1d1d1d;
  background-image: linear-gradient(rgba(0,0,0,var(--darkness)), rgba(0,0,0,var(--darkness))), url("../asset/logo-mano.png");
  background-repeat: no-repeat;
  background-position: top;
  background-size: auto 100vh;
  background-attachment: fixed;
}

.App-header {
  background: radial-gradient(circle, rgba(44, 49, 60, 0.5) 50%, rgba(40, 44, 52, 0.5) 100%);
  /* min-height: 100vh; */
  position:fixed;
  width: 100%;
  z-index: 99 !important;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: white;
  text-align: right;
  height: 5vh;
  padding: 0rem 1rem;
  backdrop-filter: blur(20px) saturate(180%);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 10px -5px, rgba(0, 0, 0, 0.14) 0px 16px 24px 2px, rgba(0, 0, 0, 0.12) 0px 6px 30px 5px;
}

.logo {
  position: absolute;
  width: fit-content;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
}

.header-container{
  align-self: flex-end;
  display: flex;
  align-items: center;
  /* padding: 2rem; */
}

.link{
  padding: 1rem;
  color: white;
  text-decoration: inherit;
  display: flex;
}

.App-body {
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 2rem;
}

.container {
  background-color: #2c2c2c;
  width: 50vh;
}

.App-link {
  color: #61dafb;
}